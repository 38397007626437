@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.navigation {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  &__subnav-wrap {
    @include mq(md) {
      display: block !important;
      height: auto !important;
      overflow: visible !important;
    }
  }

  &__subnav {
    @include unlist;

    margin-top: 18px;

    @include mq($to: md) {
      display: flex;
      flex-wrap: wrap;
      gap: 0 20px;
      margin-top: 0;
      padding-top: 5px;
      padding-bottom: 6px;
    }
  }

  &__top-right-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 50px;

    @include mq($to: xl) {
      gap: 45px 20px;
    }

    @include mq($to: md) {
      display: block;
      margin-bottom: 40px;
    }
  }

  &__top-right-cell {
    @include unlist;

    width: calc(21.6666% - 30px);
    font-size: 0;
    line-height: 0;

    &:last-child {
      #{$block-name} {
        &__nav-item {
          @include mq($to: md) {
            border-bottom: none;
          }
        }
      }
    }

    @include mq($to: xl) {
      width: calc(33.3333% - 14px);
    }

    @include mq($to: md) {
      width: auto;
    }

    &--large {
      width: calc(35% - 30px);

      @include mq($to: xl) {
        width: 100%;
        max-width: 100%;
      }

      #{$block-name} {
        &__subnav {
          display: flex;
          flex-wrap: wrap;
          gap: 0 30px;

          @include mq($to: xl) {
            gap: 0 20px;
          }

          @include mq($to: md) {
            display: flex;
          }
        }

        &__nav-subitem {
          width: calc(50% - 15px);

          @include mq($to: xl) {
            width: calc(25% - 15px);
          }

          @include mq($to: md) {
            width: calc(50% - 10px);
          }
        }
      }
    }
  }

  &__nav-item {
    position: relative;

    &--submenu-opened {
      #{$block-name} {
        &__open-menu-button {
          background-color: $green-400;

          svg {
            transform: scale(1, -1);
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 22px;

      @include mq($to: md) {
        margin-bottom: 0;
      }
    }

    @include mq($to: md) {
      border-bottom: 1px solid $purple-400;
    }
  }

  &__title-link {
    display: inline-flex;
    margin: -5px;
    padding: 5px;
    transition: opacity $transition-time;
    color: $white-100;
    font-family: $font-family--headings;
    font-size: $font-size-2xs;
    font-weight: $font-weight-xl;
    line-height: $line-height-xs;
    text-decoration: none;
    text-transform: uppercase;

    @include mq($to: md) {
      width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 22px 0;
    }

    &--grey {
      opacity: $opacity-half;

      &:hover {
        opacity: 1;
      }

      &:active {
        opacity: $opacity-half;
      }
    }

    &--white {
      &:hover {
        opacity: $opacity;
      }

      &:active {
        opacity: 1;
      }
    }

    &--no-hover {
      opacity: 1 !important;
    }

    &--active {
      opacity: 1 !important;
      color: $green-200;
    }
  }

  &__nav-subitem {
    &:not(:last-child) {
      margin-bottom: 10px;

      @include mq($to: md) {
        margin-bottom: 0;
      }
    }

    @include mq($to: md) {
      width: calc(50% - 10px);
      padding-bottom: 10px;
    }
  }

  &__nav-link {
    display: block;
    margin: -5px;
    padding: 5px;
    color: $white-100;
    font-family: $font-family;
    font-size: $font-size-xs;
    font-weight: $font-weight-m;
    line-height: $line-height-l;
    text-decoration: none;
    opacity: $opacity-half;
    transition: opacity $transition-time;

    @include mq($to: sm) {
      font-size: $font-size-2xs;
    }

    &:hover {
      opacity: 1;
    }

    &:active {
      opacity: $opacity-half;
    }

    &--active {
      opacity: 1;
      color: $green-200;
    }
  }

  &__open-menu-button-wrap {
    display: none;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    width: 60.8px;
    height: 60.8px;

    @include mq($to: md) {
      display: flex;
    }
  }

  &__open-menu-button {
    @include reset-btn;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: $border-radius-rounded;
    background-color: $purple-300;
    color: $white-100;
    transition: background-color $transition-time;

    svg {
      transform: scale(1, 1);
      transition: transform 0.2s ease 0s;
      stroke-width: 1.5;
    }
  }
}
